@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply antialiased text-gray-800;
  }
}

@layer components {
  .container {
    @apply max-w-7xl mx-auto px-4;
  }
}
